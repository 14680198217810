// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import '~@fortawesome/fontawesome-free/css/all.css';
// OverlayScrollbars
//@import '~overlayscrollbars/styles/overlayscrollbars.css';
// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';
// AdminLTE
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';

.pagination {
    margin-bottom: 0;
}

.opacity-0 {
    opacity:0!important;
}
.opacity-1 {
    opacity:0.2!important;
}
.opacity-2 {
    opacity:0.4!important;
}
.opacity-3 {
    opacity:0.6!important;
}
.opacity-4 {
    opacity:.8!important;
}
.opacity-5 {
    opacity:1!important;
}

/* Maybe even support hover opacity shifts */
.opacity-0h5 {
    opacity:0!important;
    transition: opacity .25s ease-in-out!important;
    -moz-transition: opacity .25s ease-in-out!important;
    -webkit-transition: opacity .25s ease-in-out!important;
}
.opacity-0h5:hover {
    opacity:1!important;
}

@import "media-library-pro-styles";
